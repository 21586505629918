var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { key: `key_${_vm.title}_${_vm.key}` },
    [
      _c("StudentSubHeader", {
        attrs: { "show-marking-period-picker": false },
        scopedSlots: _vm._u(
          [
            !_vm.isHomeUser
              ? {
                  key: "left-buttons",
                  fn: function () {
                    return [
                      _vm.$_userMixins_userRole == "School Admin"
                        ? _c(
                            "router-link",
                            {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover.bottomleft",
                                  modifiers: { hover: true, bottomleft: true },
                                },
                              ],
                              staticClass: "btn btn-icon kt-subheader__btn",
                              attrs: {
                                to: { name: "BadgeSetup" },
                                title: "Badge Settings",
                              },
                            },
                            [_c("i", { staticClass: "la la-cog" })]
                          )
                        : _vm._e(),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      }),
      _c(
        "div",
        {
          staticClass:
            "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
        },
        [
          _vm.student
            ? _c(
                "div",
                { staticClass: "row" },
                [
                  _c(
                    "div",
                    { staticClass: "col-12" },
                    [
                      _c("StudentHeaderPortlet", {
                        attrs: { "show-top-badge": true, "display-wide": true },
                      }),
                    ],
                    1
                  ),
                  _vm.schoolBadges.length == 0
                    ? _c("div", { staticClass: "col-12" }, [_vm._m(0)])
                    : [
                        _vm.hasBadges
                          ? _c(
                              "div",
                              { staticClass: "col-lg-6" },
                              [
                                _c("StudentAdministrativeBadges", {
                                  staticClass: "kt-portlet--height-fluid",
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.hasAwards
                          ? _c(
                              "div",
                              { staticClass: "col-lg-6" },
                              [_c("StudentAcademicAwards")],
                              1
                            )
                          : _vm._e(),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.achievementBadgeViewable,
                                expression: "achievementBadgeViewable",
                              },
                            ],
                            staticClass: "col-lg-6",
                          },
                          [
                            _c("StudentAchievementLevels", {
                              attrs: {
                                "show-data-table": true,
                                "show-award-badge": true,
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                ],
                2
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "alert alert-light alert-elevate fade show",
        attrs: { role: "alert" },
      },
      [
        _c("div", { staticClass: "alert-icon" }, [
          _c("i", { staticClass: "la la-info-circle kt-font-primary" }),
        ]),
        _c("div", { staticClass: "alert-text" }, [
          _vm._v(
            " There are no badges available for this school. Contact your school administrator to setup. "
          ),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }