var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "kt-portlet" }, [
    _vm.selectedBadge
      ? _c("div", { staticClass: "kt-portlet__head" }, [
          _c("div", { staticClass: "kt-portlet__head-label" }, [
            _c(
              "span",
              {
                staticClass: "kt-portlet__head-icon pr-3",
                style: {
                  filter: !_vm.selectedBadge.earned ? "grayscale(100%)" : "",
                },
              },
              [_vm._v(" " + _vm._s(_vm.selectedBadge.badgeIcon) + " ")]
            ),
            _c("h3", { staticClass: "kt-portlet__head-title" }, [
              _c("span", [
                _vm._v(
                  " Achievement Level " +
                    _vm._s(_vm.activeIndex + 1) +
                    " / " +
                    _vm._s(_vm.computedBadges.length) +
                    " "
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
            _c("div", { staticClass: "kt-portlet__head-actions" }, [
              _c(
                "a",
                {
                  staticClass: "btn btn-clean btn-sm btn-icon btn-icon-sm",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.previousLevel.apply(null, arguments)
                    },
                  },
                },
                [_c("i", { staticClass: "la la-angle-left" })]
              ),
              _c(
                "a",
                {
                  staticClass: "btn btn-clean btn-sm btn-icon btn-icon-sm",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      $event.preventDefault()
                      return _vm.nextLevel.apply(null, arguments)
                    },
                  },
                },
                [_c("i", { staticClass: "la la-angle-right" })]
              ),
            ]),
          ]),
        ])
      : _vm._e(),
    _c("div", { staticClass: "kt-portlet__body" }, [
      _c("div", { staticClass: "row" }, [
        _vm.computedBadges.length
          ? _c(
              "div",
              { staticClass: "col-12 text-center" },
              [
                _c(
                  "swiper",
                  {
                    ref: "achievementSwiper",
                    staticClass: "swiper",
                    attrs: { options: _vm.swiperOptions },
                  },
                  _vm._l(_vm.computedBadges, function (badge) {
                    return _c(
                      "swiper-slide",
                      {
                        key: `id_${badge.badgeId}`,
                        attrs: { title: badge.badgeTitle },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "text-center px-3",
                            staticStyle: {
                              padding: "10px 0",
                              margin: "0 auto",
                            },
                          },
                          [
                            _c("AchievementBadge", {
                              attrs: {
                                badge: badge,
                                "badge-size": "lg",
                                highlight:
                                  _vm.selectedBadge &&
                                  badge.badgeId == _vm.selectedBadge.badgeId,
                                earned: badge.earned,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.selectedBadge
          ? _c("div", { staticClass: "col-12" }, [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-start align-items-center",
                  staticStyle: { height: "100%" },
                },
                [
                  _c("div", { staticClass: "text-center w-100" }, [
                    _vm._v(
                      " " + _vm._s(_vm.selectedBadge.badgeDescription) + " "
                    ),
                  ]),
                ]
              ),
            ])
          : _vm._e(),
      ]),
      _vm.selectedBadge && _vm.showDataTable
        ? _c("div", { staticClass: "kt-section details-container pt-5" }, [
            _c("div", { staticClass: "kt-section kt-section--last" }, [
              _c("div", { staticClass: "text-center pb-3" }, [
                _c(
                  "div",
                  {
                    class: `kt-badge kt-badge--lg mb-3 px-4 ${
                      _vm.selectedBadge.earned
                        ? _vm.selectedBadge.badgeColor
                        : "Grey"
                    } kt-badge--inline kt-badge--pill`,
                  },
                  [_vm._v(" " + _vm._s(_vm.selectedBadge.badgeTitle) + " ")]
                ),
                _vm.selectedBadge.badgeId == _vm.achievementBadge.badgeId
                  ? _c("div", { staticClass: "kt-section__info" }, [
                      _vm._v(" This is the students current level. "),
                    ])
                  : _vm.selectedBadge.earned
                  ? _c("div", { staticClass: "kt-section__info" }, [
                      _vm._v(" The student has achieved this level. "),
                    ])
                  : _c("div", { staticClass: "kt-section__info" }, [
                      _vm._v(" To unlock this level, meet the goals below "),
                    ]),
              ]),
              _c("div", { staticClass: "kt-section__content" }, [
                _c("div", { staticClass: "progress" }, [
                  _c("div", {
                    class: `progress-bar progress-bar-striped progress-bar-animated ${_vm.progress.color}`,
                    style: {
                      width: `${_vm.progress.value}%`,
                    },
                    attrs: {
                      role: "progressbar",
                      "aria-valuenow": _vm.progress.value,
                      "aria-valuemin": "0",
                      "aria-valuemax": "100",
                    },
                  }),
                ]),
                _c("div", { staticClass: "kt-space-10" }),
              ]),
            ]),
            _vm.isTopLevel && _vm.selectedBadge.earned
              ? _c("p", { staticClass: "kt-section__desc pt-3" }, [
                  _vm._v(
                    " Congratulations! You have reached the highest level. "
                  ),
                ])
              : _vm._e(),
            _c("table", { staticClass: "table mt-5" }, [
              _c(
                "tbody",
                _vm._l(
                  _vm.selectedBadge.badgeRequirements,
                  function (req, idx) {
                    return _c(
                      "tr",
                      { key: "requirement_" + idx, attrs: { idx: req.idx } },
                      [
                        _c("td", { staticClass: "text-center" }, [
                          !req.meetingRequirement
                            ? _c("i", {
                                staticClass:
                                  "la la-times-circle kt-font-danger",
                                staticStyle: { "font-size": "2.4rem" },
                              })
                            : _c("i", {
                                staticClass:
                                  "la la-check-circle kt-font-primary",
                                staticStyle: { "font-size": "2.4rem" },
                              }),
                        ]),
                        _c("td", [
                          _c("div", {}, [
                            _vm._v(
                              " " +
                                _vm._s(req.badgeRequirementDescription) +
                                " "
                            ),
                          ]),
                        ]),
                        _c("td", { staticClass: "text-center" }, [
                          _c(
                            "span",
                            {
                              class: [
                                "student-score",
                                !req.meetingRequirement
                                  ? "kt-font-danger"
                                  : "kt-font-primary",
                              ],
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(req.badgeRequirementStudentScore) +
                                  " "
                              ),
                            ]
                          ),
                        ]),
                      ]
                    )
                  }
                ),
                0
              ),
            ]),
          ])
        : _vm._e(),
    ]),
    _vm.isBadgeManager
      ? _c(
          "div",
          {
            staticClass: "kt-portlet__foot kt-portlet__foot--sm kt-align-right",
          },
          [
            _c(
              "a",
              {
                staticClass: "btn btn-label-brand btn-bold btn-sm",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    $event.preventDefault()
                    return _vm.newStudentBadge.apply(null, arguments)
                  },
                },
              },
              [_vm._v(" Change Achievement Level ")]
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }