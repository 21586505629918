var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return !_vm.isHomeUser
    ? _c("div", [
        _c("div", { key: `reload_${_vm.key}`, staticClass: "kt-portlet" }, [
          _c("div", { staticClass: "kt-portlet__head" }, [
            _vm._m(0),
            _c("div", { staticClass: "kt-portlet__head-toolbar" }, [
              _c("div", { staticClass: "kt-portlet__head-actions" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-clean btn-sm btn-icon btn-icon-sm",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.previousLevel.apply(null, arguments)
                      },
                    },
                  },
                  [_c("i", { staticClass: "la la-angle-left" })]
                ),
                _c(
                  "a",
                  {
                    staticClass: "btn btn-clean btn-sm btn-icon btn-icon-sm",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        $event.preventDefault()
                        return _vm.nextLevel.apply(null, arguments)
                      },
                    },
                  },
                  [_c("i", { staticClass: "la la-angle-right" })]
                ),
              ]),
            ]),
          ]),
          _c("div", { staticClass: "kt-portlet__body" }, [
            _vm.earnedBadges.length == 0
              ? _c(
                  "div",
                  {
                    staticClass: "alert alert-secondary",
                    attrs: { role: "alert" },
                  },
                  [
                    _vm._m(1),
                    _c("div", { staticClass: "alert-text" }, [
                      _vm._v(
                        " There are no administrative badges to display. "
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm.earnedBadges.length
              ? _c(
                  "div",
                  {},
                  [
                    _c(
                      "swiper",
                      {
                        ref: "badgeSwiper",
                        staticClass: "swiper",
                        attrs: { options: _vm.swiperOptions },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "pagination",
                              fn: function () {
                                return [
                                  _c("div", {
                                    staticClass: "swiper-pagination",
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2877699954
                        ),
                      },
                      _vm._l(_vm.uniqueEarnedBadges, function (item) {
                        return _c(
                          "swiper-slide",
                          {
                            key: `badge_${item.badgeId}`,
                            attrs: { title: item.badgeTitle },
                          },
                          [
                            _c("div", { staticClass: "text-center px-3" }, [
                              item.count > 1
                                ? _c(
                                    "span",
                                    {
                                      staticClass:
                                        "kt-badge kt-badge--light kt-font-bolder kt-badge--inline kt-badge--pill",
                                      staticStyle: {
                                        position: "absolute",
                                        top: "80px",
                                        right: "10px",
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(item.count) + "x ")]
                                  )
                                : _vm._e(),
                              _c(
                                "div",
                                {
                                  class: [
                                    `inline-badge ${item.badgeColor}`,
                                    item.badgeId == _vm.selectedBadgeId
                                      ? "active"
                                      : "",
                                  ],
                                  on: {
                                    click: function ($event) {
                                      $event.stopPropagation()
                                      $event.preventDefault()
                                      return _vm.selectBadge(item)
                                    },
                                  },
                                },
                                [_vm._v(" " + _vm._s(item.badgeIcon) + " ")]
                              ),
                            ]),
                          ]
                        )
                      }),
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm.selectedBadges.length
              ? _c(
                  "div",
                  { staticClass: "kt-widget3" },
                  _vm._l(_vm.selectedBadges, function (badge) {
                    return _c(
                      "div",
                      {
                        key: `1table_${badge.badgeId}_${badge.studentBadge.schoolStaffId}`,
                        staticClass: "kt-widget3__item",
                      },
                      [
                        _c("div", { staticClass: "kt-widget3__header" }, [
                          _c("div", { staticClass: "kt-widget3__user-img" }, [
                            _c(
                              "div",
                              { staticStyle: { "font-size": "3rem" } },
                              [_vm._v(" " + _vm._s(badge.badgeIcon) + " ")]
                            ),
                          ]),
                          _c("div", { staticClass: "kt-widget3__info" }, [
                            _c("span", [
                              _vm._v(" " + _vm._s(badge.badgeTitle) + " "),
                              (_vm.schoolStaffId ==
                                badge.studentBadge.schoolStaffId ||
                                _vm.role == "School Admin") &&
                              !_vm.isHomeUser
                                ? _c(
                                    "a",
                                    {
                                      staticClass: "ml-2",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          $event.stopPropagation()
                                          $event.preventDefault()
                                          return _vm.deleteBadge(badge)
                                        },
                                      },
                                    },
                                    [_vm._v(" Remove ")]
                                  )
                                : _vm._e(),
                            ]),
                            _c("br"),
                            _c(
                              "span",
                              {
                                staticClass: "kt-widget3__time",
                                attrs: { title: badge.studentBadge.awardedOn },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.relativeDate(
                                        badge.studentBadge.awardedOn
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                          ]),
                          _c(
                            "span",
                            { staticClass: "kt-widget3__status kt-font-info" },
                            [_vm._v(" " + _vm._s(badge.teacher) + " ")]
                          ),
                        ]),
                        _c("div", { staticClass: "kt-widget3__body" }, [
                          _c("p", { staticClass: "kt-widget3__text" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  badge.studentBadge.rationale ||
                                    badge.badgeDescription
                                ) +
                                " "
                            ),
                          ]),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ]),
          !_vm.isHomeUser
            ? _c(
                "div",
                {
                  staticClass:
                    "kt-portlet__foot kt-portlet__foot--sm kt-align-right",
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-label-brand btn-bold btn-sm",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          return _vm.newStudentBadge.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v(" Add Administrative Badge ")]
                  ),
                ]
              )
            : _vm._e(),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-portlet__head-label" }, [
      _c("h3", { staticClass: "kt-portlet__head-title" }, [
        _vm._v(" Administrative Badges "),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "alert-icon" }, [
      _c("i", { staticClass: "flaticon-warning" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }