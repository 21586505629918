<!-- eslint-disable vue/no-v-html -->
<template>
<div v-if="!isHomeUser">
    <div
        :key="`reload_${key}`"
        class="kt-portlet"
    >
        <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">
                    Administrative Badges
                </h3>
            </div>
            <div class="kt-portlet__head-toolbar">
                <div class="kt-portlet__head-actions">
                    <a
                        href="#"
                        class="btn btn-clean btn-sm btn-icon btn-icon-sm"
                        @click.stop.prevent="previousLevel"
                    >
                        <i class="la la-angle-left" />
                    </a>
                    <a
                        href="#"
                        class="btn btn-clean btn-sm btn-icon btn-icon-sm"
                        @click.stop.prevent="nextLevel"
                    >
                        <i class="la la-angle-right" />
                    </a>
                </div>
            </div>
        </div>

        <div class="kt-portlet__body">
            <div
                v-if="earnedBadges.length == 0"
                class="alert alert-secondary"
                role="alert"
            >
                <div class="alert-icon">
                    <i class="flaticon-warning" />
                </div>
                <div class="alert-text">
                    There are no administrative badges to display.
                </div>
            </div>

            <div v-if="earnedBadges.length" class="">
                <swiper
                    ref="badgeSwiper"
                    class="swiper"
                    :options="swiperOptions"
                >
                    <swiper-slide
                        v-for="item in uniqueEarnedBadges"
                        :key="`badge_${item.badgeId}`"
                        :title="item.badgeTitle"
                    >
                        <div class="text-center px-3">
                            <span
                                v-if="item.count > 1"
                                class="kt-badge kt-badge--light kt-font-bolder kt-badge--inline kt-badge--pill"
                                style="position: absolute; top: 80px; right: 10px"
                            >
                                {{ item.count }}x
                            </span>
                            <div
                                :class="[
                                    `inline-badge ${item.badgeColor}`,
                                    item.badgeId == selectedBadgeId ? 'active' : '',
                                ]"
                                @click.stop.prevent="selectBadge(item)"
                            >
                                {{ item.badgeIcon }}
                            </div>
                        </div>
                    </swiper-slide>
                    <template #pagination>
                        <div class="swiper-pagination" />
                    </template>
                </swiper>
            </div>

            <div v-if="selectedBadges.length" class="kt-widget3">
                <div
                    v-for="badge in selectedBadges"
                    :key="`1table_${badge.badgeId}_${badge.studentBadge.schoolStaffId}`"
                    class="kt-widget3__item"
                >
                    <div class="kt-widget3__header">
                        <div class="kt-widget3__user-img">
                            <div style="font-size: 3rem;">
                                {{ badge.badgeIcon }}
                            </div>
                        </div>
                        <div class="kt-widget3__info">
                            <span>
                                {{ badge.badgeTitle }}
                                <a
                                    v-if="(schoolStaffId == badge.studentBadge.schoolStaffId || role == 'School Admin') && !isHomeUser"
                                    href="#"
                                    class="ml-2"
                                    @click.stop.prevent="deleteBadge(badge)"
                                >
                                    Remove
                                </a>
                            </span>
                            <br>
                            <span class="kt-widget3__time" :title="badge.studentBadge.awardedOn">
                                {{ relativeDate( badge.studentBadge.awardedOn ) }}
                            </span>
                        </div>
                        <span class="kt-widget3__status kt-font-info">
                            {{ badge.teacher }}
                        </span>
                    </div>
                    <div class="kt-widget3__body">
                        <p class="kt-widget3__text">
                            {{ badge.studentBadge.rationale || badge.badgeDescription }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!isHomeUser" class="kt-portlet__foot kt-portlet__foot--sm kt-align-right">
            <a
                href="#"
                class="btn btn-label-brand btn-bold btn-sm"
                @click.stop.prevent="newStudentBadge"
            >
                Add Administrative Badge
            </a>
        </div>
    </div>
</div>
</template>

<script>

import Vue from 'vue';
import moment from 'moment';
// @ts-ignore
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import studentMixins from '../store/mixins/studentMixins';
import utilMixins from '../store/mixins/utilMixins';
import badgeMixins from '../store/mixins/badgeMixins';
import userMixins from '../store/mixins/userMixins';
import Types from '../store/Types';
import * as network from '../network';
// @ts-ignore
import cssVars from '../css/colors.scss';

export default Vue.extend({
    name: 'StudentAdministrativeBadges',
    components: {
        swiper,
        swiperSlide,
    },
    mixins: [
        studentMixins,
        utilMixins,
        badgeMixins,
        userMixins,
    ],
    data() {
        return {
            isMounted: false,
            selectedBadgeId: null,
            saving: false,
            swiperOptions: {
                initialSlide: 0,
                slidesPerView: 'auto',
                spaceBetween: 0,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
            },
        };
    },
    computed: {
        isHomeUser() {
            const isHomeUser = this.$_userMixins_isHomeUser;
            return this.forceStudentView ? true : isHomeUser;
        },
        forceStudentView() {
            return this.$store.state.forceStudentView;
        },
        role() {
            return this.$store.state.user.school.role;
        },
        schoolStaffId() {
            return this.$store.state.user.school.schoolStaffId;
        },
        key() {
            return this.$store.state.database.reloadKey;
        },
        student() {
            return this.$_studentMixins_getStudentFromRoute;
        },
        selectedBadges() {
            const { selectedBadgeId } = this;
            if (selectedBadgeId) {
                return this.earnedBadges
                    .filter((b) => b.badgeId == selectedBadgeId);
            }
            return this.earnedBadges;
        },
        studentBadge() {
            return this.$_badgeMixins_getStudentBadgeFromRoute;
        },
        uniqueEarnedBadges() {
            const earnedBadges = [...this.earnedBadges];
            const uniqueEarnedBadges = new Map();
            earnedBadges.forEach((b) => {
                const badge = { ...b };
                badge.count = 1;
                const { badgeId } = badge;
                if (!uniqueEarnedBadges.has(badgeId)) {
                    uniqueEarnedBadges.set(badgeId, badge);
                } else {
                    const existingBadge = uniqueEarnedBadges.get(b.badgeId);
                    existingBadge.count += 1;
                    uniqueEarnedBadges.set(badgeId, existingBadge);
                }
            });
            return [...uniqueEarnedBadges.values()];
        },
        earnedBadges() {
            const { teachers } = this.$store.state.database;
            const earnedBadges = this.$_badgeMixins_getEarnedBadgesFromRoute;
            return earnedBadges
                .filter((b) => b.badgeType == 'Administrative Badge')
                .map((b) => {
                    const { schoolStaffId, courseSectionId } = b.studentBadge;
                    const teacher = teachers.find((t) => t.schoolStaffId == schoolStaffId) || null;
                    return {
                        ...b,
                        teacher: teacher ? `${teacher.lastName} ${teacher.firstName.substr(0, 1)}.` : '',
                    };
                });
        },
        swiperRef() {
            if (!this.isMounted) return null;
            return this.$refs.badgeSwiper || null;
        },
        activeIndex: {
            get() {
                const { swiperRef } = this;
                if (!swiperRef) return null;
                return swiperRef.swiper.activeIndex;
            },
            set(index) {
                const swiperRef = this.$refs.badgeSwiper;
                if (!swiperRef) return null;
                swiperRef.swiper.slideTo(index);
            },
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        relativeDate(date) {
            if (moment().format('YYYY-MM-DD') == date) return 'Today';
            return moment(date, 'YYYY-MM-DD').fromNow();
        },
        previousLevel() {
            const { swiperRef } = this;
            if (!swiperRef) return;
            swiperRef.swiper.slidePrev();
        },
        nextLevel() {
            const { swiperRef } = this;
            if (!swiperRef) return;
            swiperRef.swiper.slideNext();
        },
        goToSlide(index) {
            this.activeIndex = index;
            // if (!this.achievementSwiper) return;
            // this.achievementSwiper.slideTo(index);
        },
        populate() {
            // delay so swiper mounts
            this.swiperOptions.initialSlide = 0;
            setTimeout(() => { this.isMounted = true; }, 10);
        },
        newStudentBadge() {
            const { studentEnrollmentId } = this.$route.params;
            const { commit, state } = this.$store;
            if (studentEnrollmentId) {
                const student = state.database.students.find((x) => x.studentEnrollmentId == studentEnrollmentId);
                if (!student) return commit(Types.mutations.SET_STUDENT_BADGE_PANEL_OPEN);
                return commit(Types.mutations.SET_STUDENT_BADGE_PANEL_OPEN, {
                    students: [student],
                    badgeType: 'Administrative Badge',
                });
            }
            commit(Types.mutations.SET_STUDENT_BADGE_PANEL_OPEN);
        },
        selectBadge(badge) {
            if (!badge) return;
            if (this.selectedBadgeId == badge.badgeId) {
                this.selectedBadgeId = null;
                return;
            }

            this.selectedBadgeId = badge.badgeId;
            const { badgeColor, badgeIcon } = badge;
            const hexColor = cssVars[badgeColor];
        },
        formatPoints(value) {
            return this.formatNumber(value);
        },
        deleteBadge(badge) {
            const v = this;
            const { showError } = v;
            if (v.saving) return;
            v.saving = true;
            const { schoolId, schoolTermId } = this.$store.state.user.school;
            const { studentId, studentEnrollmentId } = this.student;
            const params = {
                url : {
                    schoolId,
                    schoolTermId,
                    studentId,
                },
                body: {
                    studentBadgeId: badge.studentBadge.studentBadgeId,
                    studentEnrollmentId,
                },
            };
            network.badges.removeStudentBadge(params, (err) => {
                v.saving = false;
                if (err) return showError(err);
                v.$store.dispatch(Types.actions.REFRESH_TABLE_BY_NAME, { tableName: 'studentBadges', callback: function() {
                    v.$store.commit(Types.mutations.BUMP_RELOAD_KEY);
                }});
            });
        },
    },
});

</script>

<style lang="scss" scoped>

.swiper {
    .swiper-inner {
        width: 100%;
        height: 148px;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 80px;
        height: 148px;
    }
    .swiper-wrapper {
        height: auto;
    }
}

.inline-badge {
    border-radius: 10px;
    padding: 10px 5px;
    margin: 10px;
    font-size: 50px;
    display: inline-block;
    font-size: 50px;
    font-weight: 700;
}

.inline-badge:hover {
    animation: shaking 0.3s;
}
@keyframes shaking {
    0% {transform: translateX(0) rotate(0deg);}
    25% {transform: translateX(5px) rotate(5deg);}
    50% {transform: translateX(0px) rotate(0deg);}
    75% {transform: translateX(-5px) rotate(-5deg);}
    100% {transform: translateX(0px) rotate(0deg);}
}

ul.badges {
    list-style: none outside none;
    margin: 0;
    padding: 0;
    text-align: center;
}

ul.badges li {
    margin: 6px;
    display: inline-block;
}

span.kt-widget__label {
    min-width: 110px;
}
a.kt-widget__data i {
    display: none;
}
a.kt-widget__data:hover i {
    display: inline-block;
}
div.kt-widget__media {
    width: 90px;
    height: 90px;
}
img.kt-widget__img {
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    border-radius: 6px !important;
    object-fit: cover;
    width: 100%;
    height: 90px;
}

img.big-picture {
    box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
    border-radius: 6px !important;
    object-fit: cover;
    width: 100%;
    height: auto;
}
</style>
