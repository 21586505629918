<template>
<div :key="`key_${title}_${key}`">
    <StudentSubHeader :show-marking-period-picker="false">
        <template
            v-if="!isHomeUser"
            #left-buttons
        >
            <router-link
                v-if="$_userMixins_userRole == 'School Admin'"
                v-b-tooltip.hover.bottomleft
                :to="{name: 'BadgeSetup'}"
                title="Badge Settings"
                class="btn btn-icon kt-subheader__btn"
            >
                <i class="la la-cog" />
            </router-link>
        </template>
    </StudentSubHeader>

    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <div v-if="student" class="row">
            <div class="col-12">
                <StudentHeaderPortlet
                    :show-top-badge="true"
                    :display-wide="true"
                />
            </div>

            <div v-if="schoolBadges.length == 0" class="col-12">
                <div role="alert" class="alert alert-light alert-elevate fade show">
                    <div class="alert-icon">
                        <i class="la la-info-circle kt-font-primary" />
                    </div>
                    <div class="alert-text">
                        There are no badges available for this school.
                        Contact your school administrator to setup.
                    </div>
                </div>
            </div>
            <template v-else>
                <div v-if="hasBadges" class="col-lg-6">
                    <StudentAdministrativeBadges class="kt-portlet--height-fluid" />
                </div>
                <div v-if="hasAwards" class="col-lg-6">
                    <StudentAcademicAwards />
                </div>
                <div v-show="achievementBadgeViewable" class="col-lg-6">
                    <StudentAchievementLevels
                        :show-data-table="true"
                        :show-award-badge="true"
                    />
                </div>
            </template>
        </div>
    </div>
</div>
</template>

<script lang="ts">

import StudentAchievementLevels from '../components/StudentAchievementLevels.vue';
import StudentAcademicAwards from '../components/StudentAcademicAwards.vue';
import StudentAdministrativeBadges from '../components/StudentAdministrativeBadges.vue';
import StudentHeaderPortlet from '../components/StudentHeaderPortlet.vue';
import studentMixins from '../store/mixins/studentMixins';
import portfolioMixins from '../store/mixins/portfolioMixins';
import StudentSubHeader from './StudentSubHeader.vue';
import userMixins from '../store/mixins/userMixins';
import badgeMixins from '../store/mixins/badgeMixins';
import Types from '../store/Types';

export default {
    name: 'StudentBadges',
    components: {
        StudentSubHeader,
        StudentAchievementLevels,
        StudentAcademicAwards,
        StudentAdministrativeBadges,
        StudentHeaderPortlet,
    },
    mixins: [studentMixins, portfolioMixins, userMixins, badgeMixins],
    data() {
        return {
            key: 0,
        };
    },
    computed: {
        isHomeUser() {
            const isHomeUser = this.$_userMixins_isHomeUser;
            return this.forceStudentView ? true : isHomeUser;
        },
        forceStudentView() {
            return this.$store.state.forceStudentView;
        },
        cache() {
            const cacheKey = this.$_portfolioMixins_getPortfolioCacheKey;
            const { cache } = this.$store.state.database;
            return Boolean(cache.find((k) => k.cacheKey == cacheKey) || null);
        },
        achievementBadge() {
            return this.$_badgeMixins_getAchievementLevelFromRoute;
        },
        achievementBadgeViewable() {
            const {achievementBadge} = this;
            if (!achievementBadge) return false;
            if (this.isHomeUser) {
                const role = this.$store.state.user.school.role;
                if (role == 'Student') return achievementBadge.studentShare;
                if (role == 'Guardian') return achievementBadge.guardianShare;
                return achievementBadge.studentShare;
            } else {
                return true;
            }
        },
        student() {
            const student = this.$_studentMixins_getStudentFromRoute;
            if (!student) return null;
            student.address = student.street ? `${student.street} \n${student.city}, ${student.state} ${student.zip}` : null;
            return student;
        },
        hasBadge() {
            return Boolean(this.studentBadge);
        },
        schoolBadges() {
            const { gradeLevel } = this.student;
            // remove badges not valid for this student
            return this.$store.state.database.badges.filter((b) => {
                if (b.deleted) return false;
                // this isnt by grde level, show it
                if (b.badgeLimiterType == null) return true;

                // limited by grade level
                if (b.badgeLimiterType == 'Grade Level') {
                    const hasGLReqs = b.badgeRequirements.filter((br) => br.badgeRequirementLimiter == gradeLevel);
                    return (hasGLReqs.length > 0);
                }
                return false;
            });
        },
        studentBadge() {
            return this.$_badgeMixins_getStudentBadgeFromRoute;
        },
        topBadge() {
            return this.$_badgeMixins_getStickyBadgeFromRoute || this.$_badgeMixins_getAchievementLevelFromRoute;
        },
        earnedBadges() {
            return this.$_badgeMixins_getEarnedBadgesFromRoute;
        },
        hasBadges() {
            if (this.isHomeUser) return false;
            return this.earnedBadges.some((b) => b.badgeType == 'Administrative Badge');
        },
        hasAwards() {
            return this.earnedBadges.some((b) => b.badgeType == 'Academic Award');
        },
        title() {
            const route = this.$route;
            return route.meta.title || null;
        },
        studentBadges() {
            return this.$_badgeMixins_getStudentBadgeFromRoute;
        },
    },
    watch: {
        '$route.params.studentEnrollmentId': {
            handler() {
                this.key += 1;
            },
        },
        cache: {
            handler() {
                this.key += 1;
            },
        },
    },
    methods: {
        newAnecdotal() {
            const { studentEnrollmentId } = this.$route.params;
            if (studentEnrollmentId) {
                const student = this.$store.state.database.students.find((x) => x.studentEnrollmentId == studentEnrollmentId);
                if (!student) return this.$store.commit(Types.mutations.SET_ANECDOTAL_CREATE_OPEN);
                return this.$store.commit(Types.mutations.SET_ANECDOTAL_CREATE_OPEN, { students: [student] });
            }
            this.$store.commit(Types.mutations.SET_ANECDOTAL_CREATE_OPEN);
        },
    },
};
</script>
