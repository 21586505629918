<template>
<div class="kt-portlet">
    <div v-if="selectedBadge" class="kt-portlet__head">
        <div class="kt-portlet__head-label">
            <span
                :style="{
                    filter: !selectedBadge.earned ? 'grayscale(100%)' : '',
                }"
                class="kt-portlet__head-icon pr-3"
            >

                {{ selectedBadge.badgeIcon }}
            </span>
            <h3 class="kt-portlet__head-title">
                <span> Achievement Level {{ activeIndex + 1 }} / {{ computedBadges.length }} </span>
            </h3>
        </div>
        <div class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-actions">
                <a
                    href="#"
                    class="btn btn-clean btn-sm btn-icon btn-icon-sm"
                    @click.stop.prevent="previousLevel"
                >
                    <i class="la la-angle-left" />
                </a>
                <a
                    href="#"
                    class="btn btn-clean btn-sm btn-icon btn-icon-sm"
                    @click.stop.prevent="nextLevel"
                >
                    <i class="la la-angle-right" />
                </a>
            </div>
        </div>
    </div>
    <div class="kt-portlet__body">
        <div class="row">
            <div v-if="computedBadges.length" class="col-12 text-center">
                <swiper
                    ref="achievementSwiper"
                    class="swiper"
                    :options="swiperOptions"
                >
                    <swiper-slide
                        v-for="badge in computedBadges"
                        :key="`id_${badge.badgeId}`"
                        :title="badge.badgeTitle"
                    >
                        <div
                            class="text-center px-3"
                            style="padding: 10px 0; margin: 0 auto;"
                        >
                            <AchievementBadge
                                :badge="badge"
                                badge-size="lg"
                                :highlight="selectedBadge && badge.badgeId == selectedBadge.badgeId"
                                :earned="badge.earned"
                            />
                        </div>
                    </swiper-slide>
                </swiper>
            </div>

            <div v-if="selectedBadge" class="col-12">
                <div style="height: 100%;" class="d-flex justify-content-start align-items-center">
                    <div class="text-center w-100">
                        {{ selectedBadge.badgeDescription }}
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="selectedBadge && showDataTable"
            class="kt-section details-container pt-5"
        >
            <div class="kt-section kt-section--last">
                <div class="text-center pb-3">
                    <div :class="`kt-badge kt-badge--lg mb-3 px-4 ${selectedBadge.earned ? selectedBadge.badgeColor : 'Grey'} kt-badge--inline kt-badge--pill`">
                        {{ selectedBadge.badgeTitle }}
                    </div>

                    <div v-if="selectedBadge.badgeId == achievementBadge.badgeId" class="kt-section__info">
                        This is the students current level.
                    </div>

                    <div v-else-if="selectedBadge.earned" class="kt-section__info">
                        The student has achieved this level.
                    </div>

                    <div v-else class="kt-section__info">
                        To unlock this level, meet the goals below
                    </div>
                </div>

                <!-- <span v-if="selectedBadge.earned == false" class="kt-section__info">
                    {{ selectedBadge.badgeTitle }} -
                    {{ selectedBadge.badgeDescription }}
                </span> -->

                <div class="kt-section__content">
                    <div class="progress">
                        <div
                            :class="`progress-bar progress-bar-striped progress-bar-animated ${progress.color}`"
                            role="progressbar"
                            :aria-valuenow="progress.value"
                            aria-valuemin="0"
                            aria-valuemax="100"
                            :style="{
                                width: `${progress.value}%`,
                            }"
                        />
                    </div>
                    <div class="kt-space-10" />
                </div>
            </div>
            <p v-if="isTopLevel && selectedBadge.earned" class="kt-section__desc pt-3">
                Congratulations! You have reached the highest level.
            </p>

            <table class="table mt-5">
                <tbody>
                    <tr
                        v-for="(req, idx) in selectedBadge.badgeRequirements"
                        :key="'requirement_' + idx"
                        :idx="req.idx"
                    >
                        <td class="text-center">
                            <i
                                v-if="!req.meetingRequirement"
                                class="la la-times-circle kt-font-danger"
                                style="font-size: 2.4rem;"
                            />
                            <i
                                v-else
                                class="la la-check-circle kt-font-primary"
                                style="font-size: 2.4rem;"
                            />
                        </td>
                        <td>
                            <div class="">
                                {{ req.badgeRequirementDescription }}
                            </div>
                        </td>

                        <td class="text-center">
                            <span :class="['student-score', !req.meetingRequirement ? 'kt-font-danger' : 'kt-font-primary']">
                                {{ req.badgeRequirementStudentScore }}
                            </span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div v-if="isBadgeManager" class="kt-portlet__foot kt-portlet__foot--sm kt-align-right">
        <a
            href="#"
            class="btn btn-label-brand btn-bold btn-sm"
            @click.stop.prevent="newStudentBadge"
        >
            Change Achievement Level
        </a>
    </div>
</div>
</template>

<script lang="ts">

// @ts-ignore
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import Vue from 'vue';
import * as network from '../network';
import studentMixins from '../store/mixins/studentMixins';
import portfolioMixins from '../store/mixins/portfolioMixins';
import badgeMixins from '../store/mixins/badgeMixins';
import AchievementBadge from './AchievementBadge.vue';
import Types from '../store/Types';

export default Vue.extend({
    name: 'StudentAchievementLevels',
    components: {
        AchievementBadge,
        swiper,
        swiperSlide,
    },
    mixins: [
        studentMixins,
        portfolioMixins,
        badgeMixins,
    ],
    props: {
        showDataTable: {
            type: Boolean,
            default: true,
        },
        showAwardBadge: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            debounce: null,
            computedBadges: [],
            loading: false,
            isMounted: false,
            swiperOptions: {
                initialSlide: 0,
                slidesPerView: 'auto',
                centeredSlides: true,
                spaceBetween: 30,
            },
        };
    },
    computed: {
        isBadgeManager() {
            const userId = this.$store.state.user.mappedUserId || this.$store.state.user.userId;
            const { achievementBadge } = this;
            if (!achievementBadge) return false;
            const { badgeManagers } = achievementBadge;
            if (badgeManagers.length === 0) return true;
            const isManager = badgeManagers.some(function(bm) {
                return bm.userId == userId;
            });
            return isManager;
        },
        student() {
            return this.$_studentMixins_getStudentFromRoute;
        },
        studentBadge() {
            return this.$_badgeMixins_getStudentBadgeFromRoute;
        },
        isHomeUser() {
            const isHomeUser = this.$_userMixins_isHomeUser;
            return this.forceStudentView ? true : isHomeUser;
        },
        forceStudentView() {
            return this.$store.state.forceStudentView;
        },
        achievementBadge() {
            return this.$_badgeMixins_getAchievementLevelFromRoute;
        },
        anecdotalEventCategories() {
            return this.$store.state.database.anecdotalEventCategories;
        },
        isTopLevel() {
            return this.activeIndex === this.computedBadges.length - 1;
        },
        totalEarnedRequirements() {
            const { totalRequirements } = this;
            return totalRequirements.filter((r) => r.meetingRequirement).length;
        },
        totalNeededRequirements() {
            const { totalRequirements } = this;
            return totalRequirements.filter((r) => !r.meetingRequirement).length;
        },
        totalRequirements() {
            const { computedBadges } = this;
            const requirements = [];
            computedBadges.forEach((b) => {
                b.badgeRequirements.forEach((r) => {
                    requirements.push(r);
                });
            });
            return requirements;
        },
        selectedBadge() {
            const { computedBadges, activeIndex, anecdotalEventCategories } = this;
            if (activeIndex === null) return null;
            if (!computedBadges.length) return null;
            if (activeIndex > computedBadges.length - 1) return null;
            const b = computedBadges[activeIndex];
            const { badgeRequirements } = b;
            const badge = { ...b };
            badge.badgeRequirements = badgeRequirements.map((r) => {
                const req = { ...r };
                if (req.badgeRequirementStudentScore) {
                    req.badgeRequirementStudentScore = Math.round(r.badgeRequirementStudentScore);
                }
                req.badgeRequirementDescription = `${req.badgeRequirementType} ${req.badgeRequirementOperator} ${req.badgeRequirementThreshold}`;
                if (req.anecdotalEventCategoryId) {
                    const anecdotalEventCategory = anecdotalEventCategories.find((c) => c.anecdotalEventCategoryId == req.anecdotalEventCategoryId);
                    if (anecdotalEventCategory) {
                        req.badgeRequirementDescription = `${anecdotalEventCategory.anecdotalEventCategoryTitle} ${req.badgeRequirementOperator} ${req.badgeRequirementThreshold}`;
                        // req.badgeRequirementDescription = `${req.badgeRequirementDescription} ()`;
                    }
                }
                return req;
            });
            return badge;
        },
        requirementProgress() {
            const { selectedBadge } = this;
            if (!selectedBadge) return 0;
            const { badgeRequirements } = selectedBadge;
            const total = badgeRequirements.length;
            const met = badgeRequirements.filter((r) => r.meetingRequirement).length;
            return Math.round((met / total) * 100);
        },
        progress() {
            const { selectedBadge } = this;
            if (!selectedBadge) return { value: 0, color: 'Grey' };
            if (selectedBadge.earned == false) return { value: 0, color: 'Grey' };

            const totalParts = this.computedBadges.length;
            let thisPart = 1;
            let color = 'Grey';
            const { badgeId } = selectedBadge;
            this.computedBadges.forEach((b, idx) => {
                if (badgeId == b.badgeId && b.earned) {
                    thisPart = idx + 1;
                    color = b.badgeColor;
                }
            });
            const value = Math.round((thisPart / totalParts) * 100);
            return { value, color };
        },
        percentProgress() {
            const { totalRequirements, totalEarnedRequirements, selectedBadge } = this;
            if (selectedBadge.earned) {
                // i think ach badges need a level property to dp this better
                return 0;
            }
            return Math.round((totalEarnedRequirements / totalRequirements.length) * 100);
        },
        isLevelEarned() {
            const { selectedBadge } = this;
            if (!selectedBadge) return false;
            return selectedBadge.earned;
        },
        swiperRef() {
            if (!this.isMounted) return null;
            return this.$refs.achievementSwiper || null;
        },
        activeIndex: {
            get() {
                const { swiperRef } = this;
                if (!swiperRef) return null;
                return swiperRef.swiper.activeIndex;
            },
            set(index) {
                const swiperRef = this.$refs.achievementSwiper;
                if (!swiperRef) return null;
                swiperRef.swiper.slideTo(index);
            },
        },
        reloadKey() {
            return this.$store.state.database.reloadKey;
        },
    },
    watch: {
        reloadKey: {
            handler() {
                const { goToSlide, computedBadges } = this;
                if (!this.achievementBadge) return;
                const { badgeId } =  this.achievementBadge;
                computedBadges.forEach((b, idx) => {
                    if (b.badgeId == badgeId) {
                        goToSlide(idx);
                    }
                });
                this.debouncePopulate();
            },
        },
    },
    mounted() {
        this.populate();
    },
    methods: {
        debouncePopulate() {
            const { populate } = this;
            if (this.debounce) {
                clearTimeout(this.debounce);
            }
            this.debounce = setTimeout(() => {
                populate();
            }, 1000);
        },
        newStudentBadge() {
            const { studentEnrollmentId } = this.$route.params;
            const { commit, state } = this.$store;
            if (studentEnrollmentId) {
                const student = state.database.students.find((x) => x.studentEnrollmentId == studentEnrollmentId);
                if (!student) return commit(Types.mutations.SET_STUDENT_BADGE_PANEL_OPEN);
                return commit(Types.mutations.SET_STUDENT_BADGE_PANEL_OPEN, {
                    students: [student],
                    badgeType: 'Achievement Level',
                });
            }
            commit(Types.mutations.SET_STUDENT_BADGE_PANEL_OPEN);
        },
        previousLevel() {
            const { swiperRef } = this;
            if (!swiperRef) return;
            swiperRef.swiper.slidePrev();
        },
        nextLevel() {
            const { swiperRef } = this;
            if (!swiperRef) return;
            swiperRef.swiper.slideNext();
        },
        goToSlide(index) {
            this.activeIndex = index;
            // if (!this.achievementSwiper) return;
            // this.achievementSwiper.slideTo(index);
        },
        populate() {
            const { showError, achievementBadge, $store } = this;
            if (!achievementBadge) return;

            const { badgeId } = achievementBadge;
            const { schoolId, schoolTermId } = this.$store.state.user.school;
            const { studentEnrollmentId } = this.student;

            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                    studentEnrollmentId,
                    badgeId,
                },
            };
            this.loading = true;
            network.badges.checkStudentBadgeRequirements(params, (err, res) => {
                this.loading = false;
                if (err) return showError(err);
                $store.commit(Types.mutations.UPSERT_STUDENT_BADGES, res.studentBadges);
                const computedBadges = res.computedBadges
                    .map((b) => {
                        const badge = { ...b };
                        if (!badge.earned) {
                            badge.badgeTitle = 'Locked';
                            badge.badgeColor = '#f5f5f5';
                            badge.badgeIcon = '📦';
                        }
                        return badge;
                    })
                    .sort((a, b) => a.badgeRank > b.badgeRank ? 1 : -1); // lowest to highest

                this.computedBadges = computedBadges;

                // delay so swiper mounts
                this.swiperOptions.initialSlide = 0;
                computedBadges.forEach((b, idx) => {
                    if (b.badgeId == badgeId) {
                        this.swiperOptions.initialSlide = idx;
                    }
                });
                setTimeout(() => { this.isMounted = true; }, 10);
            });
        },

    },
});
</script>

<style lang="scss" scoped>

.swiper {
    .swiper-inner {
        width: 100%;
        height: 148px;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 148px;
        height: 148px;
    }
    .swiper-pagination {
        width: 100%;
    }
}

 .table tr td {
    vertical-align: middle;
 }
 .student-score {
    font-size: 1.4rem;
    font-weight: 600;
 }
</style>
